import { useQueryClient } from '@tanstack/react-query';
import { cn } from '@utima/ui';
import { RotateCw } from 'lucide-react';
import { memo, useState, useTransition } from 'react';
import { v7 as uuidV7 } from 'uuid';

import { useChatConfig } from '@/contexts/ChatConfigProvider';
import { chatKeys } from '@/services/queryClient';
import { useBoundStore } from '@/store/store';

type ResetConversationProps = {
  disabled?: boolean;
  onReset?: () => void;
};

export const ResetConversation = memo(function ResetConversation({
  disabled = false,
  onReset,
}: ResetConversationProps) {
  const { chatId } = useChatConfig();
  const { setThreadId } = useBoundStore(state => ({
    setThreadId: state.setThreadId,
  }));

  const [_, startTransition] = useTransition();
  const [isLoading, setIsLoading] = useState(false);

  /**
   * Create new thread to reset the conversation.
   */
  const queryClient = useQueryClient();

  /**
   * Reset conversation by creating new one and invalidating
   * the chat query storage.
   */
  const handleReset = async () => {
    onReset?.();
    startTransition(() => {
      setIsLoading(true);
    });

    try {
      // Create new thread id
      setThreadId(uuidV7());

      // Invalidate chat query storage.
      queryClient.invalidateQueries({
        queryKey: chatKeys.detail(chatId),
      });
    } catch (error) {
      console.error(error);
    } finally {
      startTransition(() => {
        setIsLoading(false);
      });
    }
  };

  return (
    <button
      type='button'
      className='flex items-center justify-center text-primary-fg transition-transform hover:scale-110 active:scale-100'
      onClick={handleReset}
      disabled={disabled}
    >
      <RotateCw
        className={cn('size-6', isLoading && 'animate-spin')}
        strokeWidth={1.5}
      />
    </button>
  );
});
