import type { CreateChatMessageDTO } from '@kanbu/schema/contracts';
import { ChatRole } from '@kanbu/schema/enums';
import { useMutation } from '@tanstack/react-query';

import { useChatConfig } from '@/contexts/ChatConfigProvider';
import type { UseChatReturn } from '@/hooks/chatTypes';
import { aiCoreApi } from '@/services/aiCoreClient';
import { useBoundStore } from '@/store/store';

interface FAQItemProps {
  id: string;
  title: string;
  question: string;
  answer: string;
  childrenCount: number;
  insert: UseChatReturn['insert'];
}

export function FAQItem({
  id,
  title,
  question,
  answer,
  insert,
  childrenCount,
}: FAQItemProps) {
  const { chatId } = useChatConfig();
  const [threadId, setFAQ, setIsInFAQLoop] = useBoundStore(state => [
    state.threadId,
    state.setFAQ,
    state.setIsInFAQLoop,
  ]);

  const { mutateAsync: increment } = useMutation({
    mutationFn: () =>
      aiCoreApi.faqs.increment({
        chatId,
        faqId: id,
      }),
  });

  const { mutateAsync: createMessages } = useMutation({
    mutationFn: async (messages: CreateChatMessageDTO[]) => {
      await aiCoreApi.threads.messages.create({
        chatId,
        threadId: threadId!,
        messages,
      });

      setIsInFAQLoop(true);
    },
  });

  const handleClick = async () => {
    // Insert question
    setFAQ(id, childrenCount);
    insert(question);

    await Promise.all([
      // Increase click count
      increment(),
      // Save to thread history
      createMessages([
        {
          thread: id,
          role: ChatRole.User,
          content: question,
        },
        {
          thread: id,
          role: ChatRole.Assistant,
          content: answer,
        },
      ]),
      // Insert answer (with timeout to make it feel more real)
      insert(answer, ChatRole.Assistant, { timeout: 1500 }),
    ]);
  };

  if (!threadId) {
    return null;
  }

  return (
    <button
      type='button'
      className='rounded-full border-2 border-secondary px-4 py-2 text-sm font-medium text-secondary transition-all hover:scale-105 active:scale-100'
      onClick={handleClick}
    >
      {title}
    </button>
  );
}
