import type { StateCreator } from 'zustand';

export interface FAQSlice {
  currentFAQId?: string;
  childrenCount?: number;
  isInFAQLoop: boolean;
  setFAQ: (id: string, childrenCount: number) => void;
  setIsInFAQLoop: (val: boolean) => void;
}

export const createFAQSlice: StateCreator<FAQSlice> = set => ({
  currentFAQId: undefined,
  childrenCount: undefined,
  setFAQ: (id: string, childrenCount: number) =>
    set({ currentFAQId: id, childrenCount: childrenCount }),
  isInFAQLoop: false,
  setIsInFAQLoop: (val: boolean) => set({ isInFAQLoop: val }),
});
