/**
 * Checks if the given item is an object.
 *
 * @example
 * const obj = { a: 1, b: 2 };
 * const isObj = isObject(obj);
 *
 * -> isObj will be true
 */
function isObject(item: unknown): item is Record<string, any> {
  return typeof item === 'object' && item !== null && !Array.isArray(item);
}

/**
 * Deep merges two objects.
 *
 * @example
 * const obj1 = { a: 1, b: { c: 2, d: 3 } };
 * const obj2 = { b: { c: 4, e: 5 }, f: 6 };
 * const merged = deepMerge(obj1, obj2);
 *
 * -> merged will be { a: 1, b: { c: 4, d: 3, e: 5 }, f: 6 }
 */
export function deepMerge<T extends Record<string, any>>(
  target: T,
  source: Partial<T>,
): T {
  const merged = structuredClone(target);
  for (const key in source) {
    if (source[key] !== undefined) {
      merged[key] =
        isObject(merged[key]) && isObject(source[key])
          ? deepMerge(merged[key], source[key])
          : structuredClone(source[key]);
    }
  }

  return merged;
}

/**
 * Converts a PascalCase string to a kebab-case string.
 *
 * @example
 * const str = 'MyVariableName';
 * const kebab = pascalToKebab(str);
 *
 * -> kebab will be 'my-variable-name'
 */
export function pascalToKebab(str: string) {
  return str.replaceAll(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
}
